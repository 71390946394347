<template>
    <Dialog header='Transfer Funds' v-model:visible="display" :modal="true" :draggable="false" class='account-transfer-modal' @hide="onHide">

            <table class='transfer-table'>
            <tr>
                <td class='label-container'> <span class='input-label'>Source Account:</span></td>
                <td class='value-container'>
                    {{account.name || account.accountName}}
                </td>
            </tr>
            <tr>
                <td class='label-container'><span class='input-label'>Total Cash Available: </span></td>
                 <td class='value-container'><span>{{cashBalance}} </span></td>
            </tr>
            <tr>
                <td class='label-container'><span class='input-label'>Select transfer destination:</span></td>
            </tr>
            </table>


            <div class="transfer-destination-options-container">
                <div v-for="option in transferDestinationOptions" :key="option.id">
                    <RadioButton v-model="transferDestinationOption" :inputId="option.id" :name="option.label" :value="option.id"/>
                    <label :for="option.id">{{ option.label }}</label>
                </div>
            </div>

            <TransfereeFundsSearch v-if="transferDestinationOption !== transferDestinationOptionsIds.yourAccount && transferDestinationOption !== transferDestinationOptionsIds.yourGroupAccount" :selectedGroup="selectedGroup"
            :searchForGroup="transferDestinationOption === transferDestinationOptionsIds.groupAccount" @selected-transferee="selectedTransferee"/>

            <table class='transfer-table'>
                <tr v-if="transferDestinationOption === transferDestinationOptionsIds.groupAccount || transferDestinationOption === transferDestinationOptionsIds.userAccount">
                    <td class='label-container'>
                        <span class='input-label' v-if="transferDestinationOption === transferDestinationOptionsIds.userAccount">Currently Selected User:</span>
                        <span class='input-label' v-else>Currently Selected Group:</span>
                    </td>
                    <td class='value-container'><b class="black">{{selectedTransfereeAccount ? selectedTransfereeAccount?.name : '-' }} </b></td>
                </tr>
                <tr v-if="accountDataList.length > 0">
                    <td class='label-container'> <span class='input-label'>Destination Account:</span></td>
                    <td class='value-container'>
                        <Dropdown class="account-type-dropdown dropdown" v-model="selectedAccount" :options="accountDataList" placeholder="Select Destination Account" optionLabel="name" />
                    </td>
                </tr>
            
                
                <tr>
                    <td class='label-container'><span class='input-label'>Transfer Amount:</span></td>
                    <td class='value-container'>
                        <span class="p-input-icon-right">
                            <i class="pi pi-dollar" />
                            <InputNumber class='amount-input' v-model="amount" :minFractionDigits="2" :max="9999999999" />
                        </span>
                    </td>
                </tr>
            </table>
       
        <div class='buttons-container'>
            <Button label='Cancel' class='green-button__secondary' @click="onClose()"/>
            <Button label='Transfer' class='green-button__secondary' @click="completeTransfer()" :disabled="disableTransferButton"/>
        </div>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import RadioButton from 'primevue/radiobutton';
import TransfereeFundsSearch from '../TransfereeFundsSearch.vue';

import UserUtils from '../../../utilities/UserUtils';
import FormattingUtils from '../../../utilities/FormattingUtils';
import PortfolioService from '../../../service/PortfolioService';
import GroupService from '../../../service/GroupService';

import { CANADIAN_CURRENCY_TYPE, EDUCATIONAL_ACCOUNT_TYPE, PAPER_TRADING_ACCOUNT_TYPE } from '../../../common/constants';
import EventBus from '../../../event-bus';

const transferDestinationOptionsIds = {
    yourAccount:"yourAccount",
    userAccount:"userAccount",
    groupAccount:"groupAccount",
    yourGroupAccount: "yourGroupAccount",
}

const transferDestinationOptionsForUser = [
    {
        id: transferDestinationOptionsIds.yourAccount,
        label: 'Your account'
    },
    {
        id: transferDestinationOptionsIds.userAccount,
        label: 'Another user account'
    },
    {
        id: transferDestinationOptionsIds.groupAccount,
        label: 'Group account'
    },
];

const transferDestinationOptionsForBasicUser = [
    {
        id: transferDestinationOptionsIds.yourAccount,
        label: 'Your account'
    },
   
    {
        id: transferDestinationOptionsIds.groupAccount,
        label: 'Group account'
    },
];





const transferDestinationOptionsForGroup = [
    {
        id: transferDestinationOptionsIds.yourGroupAccount,
        label: 'Your group account'
    },
    {
        id: transferDestinationOptionsIds.userAccount,
        label: 'User account'
    },
    {
        id: transferDestinationOptionsIds.groupAccount,
        label: 'Group account'
    },
];

const transferDestinationOptionsForBasicGroup = [
    {
        id: transferDestinationOptionsIds.yourGroupAccount,
        label: 'Your group account'
    },
   
    {
        id: transferDestinationOptionsIds.groupAccount,
        label: 'Group account'
    },
];

export default {
    name: 'AccountTransferModal',
    emits: ['complete-transfer'],
    props: ['selectedGroup'],
    components: {
        Dialog,
        Dropdown,
        InputNumber,
        RadioButton,
        TransfereeFundsSearch
    },
    

    data() {
        return {
            //CONSTANTS
            CANADIAN_CURRENCY_TYPE,

            transferDestinationOptionsForUser,
            transferDestinationOptionsForGroup,
            transferDestinationOptionsIds,

            display: false,
            account: null,
            amount: null,
            selectedAccountName: null,
            selectedAccount: null,
            accountDataList: [],
            ownAccountDataList: [],
            ownGroupAccountDataList: [],
            selectedTransfereeAccount: null,
            formatter: new FormattingUtils(),
            transferDestinationOption: null,

            selectedItem: {displayText:""},
            filteredItems: null,
        }
    },

    computed: {
        cashBalance() {
            
            return this.formatter.formatAsCurrency(this.account.totalValue);
        },

        transferDestinationOptions() {
            if( UserUtils.isPremiumSubscriber() ) {
                return this.selectedGroup ? transferDestinationOptionsForGroup : transferDestinationOptionsForUser;
            }
            else {
                return this.selectedGroup ? transferDestinationOptionsForBasicGroup : transferDestinationOptionsForBasicUser;
            }
        },

        disableTransferButton() {

            let isDisabled = false;

            if (this.transferDestinationOption === transferDestinationOptionsIds.yourAccount || this.transferDestinationOption === this.transferDestinationOptionsIds.yourGroupAccount) {
                isDisabled = !this.amount || !this.selectedAccount;
            } else {
                const baseCondition = !this.amount || !this.selectedTransfereeAccount;
                
                if (this.transferDestinationOption === transferDestinationOptionsIds.userAccount) {
                    if (UserUtils.isUser(this.selectedTransfereeAccount?.userId)) {
                        isDisabled = baseCondition || !this.selectedAccount;
                    } else {
                        isDisabled = baseCondition;
                    }
                } else {
                    if (this.accountDataList.length > 1) {
                        isDisabled = baseCondition || !this.selectedAccount;
                    } else {
                        isDisabled = baseCondition;
                    }
                }
            }

            return isDisabled;
        }
    },
    watch: {
        transferDestinationOption(newVal) {
            if (newVal !== null) {
                this.accountDataList = [];
                this.selectedAccount = null;
                this.selectedTransfereeAccount = null;
                EventBus.emit('refresh-transferee-search');

                if (newVal === transferDestinationOptionsIds.yourAccount) {
                    if (this.ownAccountDataList.length === 0) {
                        this.fetchOwnAccounts();
                    }

                    this.accountDataList = this.ownAccountDataList;
                } else if (newVal === transferDestinationOptionsIds.yourGroupAccount) {
                    if (this.ownGroupAccountDataList.length === 0) {
                        this.fetchOwnGroupAccounts();
                    }

                    this.accountDataList = this.ownGroupAccountDataList;
                }
            }
        }
    },

    methods: {

        open(account) {
            this.account = account;
            this.ownAccountDataList = [];
            this.ownGroupAccountDataList = [];
            this.selectedAccount = null;
            this.amount = null;
            this.transferDestinationOption = !this.selectedGroup ? transferDestinationOptionsIds.yourAccount : transferDestinationOptionsIds.yourGroupAccount;

            this.display = true;
        },

        onHide() {
            this.transferDestinationOption = null;
        },

        onClose() {
            this.display = false;
        },

        fetchOwnAccounts() {
            PortfolioService.getListOfAccounts().then(resp => {
                if (resp.data.status === 'success') {
                    resp.data.accounts.forEach((acct) => {
                        if( (acct.accountTypeId === PAPER_TRADING_ACCOUNT_TYPE['ID'] || acct.accountTypeId === EDUCATIONAL_ACCOUNT_TYPE['ID']) && acct.investmentAccountId !== this.account.investmentAccountId){
                            this.ownAccountDataList.push(acct);
                        }
                    });
                }
            });
            PortfolioService.getCashAccounts().then(resp => {
                if( resp.data.status == 'success'){
                    let cashAccounts = resp.data.cashAccounts;
                    cashAccounts.forEach(acct => {
                        //console.log("comparing "+ acct.investmentAccountId+" with "+ this.account.investmentAccountId)
                        if( acct.investmentAccountId !== this.account.investmentAccountId) {
                            this.ownAccountDataList.push(acct);
                        }
                    });
                } else {
                    console.log("error getting cash accounts " + resp.data.message);
                }
            });
        },

        fetchOwnGroupAccounts() {
            if(this.selectedGroup){
                GroupService.getCollaborativeGroupPortfolios(this.selectedGroup.groupId).then(resp => {
                    if (resp.data.status == 'success') {
                        const groupPortfolios = resp.data.groupPortfolios;
                        groupPortfolios.forEach(portfolio => {
                            portfolio?.accounts?.forEach(acct => {
                                if( acct.investmentAccountId != this.account.investmentAccountId) {
                                    //console.log("portfolio.name="+portfolio.name);
                                    acct['name'] = portfolio.name+"-"+acct.accountName;
                                    acct['fullName'] = portfolio.name+"-"+acct.accountName;
                                    this.ownGroupAccountDataList.push(acct);
                                }
                            });
                        });

                        console.log("getCollaborativeGroupPortfolios", this.accountDataList);
                    } else {
                        console.log("error getting accounts " + resp.data.message);
                    }
                });
                GroupService.getGroupCashAccounts(this.selectedGroup.groupId).then(resp => {
                    if( resp.data.status == 'success'){
                        let cashAccounts = resp.data.cashAccounts;
                        cashAccounts.forEach(acct => {
                            if( acct.investmentAccountId != this.account.investmentAccountId) {
                                this.ownGroupAccountDataList.push(acct);
                            }
                        });
                    } else {
                        console.log("error getting cash accounts " + resp.data.message);
                    }
                });
            }
        },

        selectedTransferee(transferee) {
            this.accountDataList = [];

            this.selectedTransfereeAccount = transferee;

            if (this.transferDestinationOption === transferDestinationOptionsIds.groupAccount) {
                if (this.selectedTransfereeAccount.groupId && this.selectedTransfereeAccount.isMember) {
                    GroupService.getCollaborativeGroupPortfolios(this.selectedTransfereeAccount.groupId).then(resp => {
                        if (resp.data.status == 'success') {
                            let groupPortfolios = [];

                            if (resp.data.groupPortfolios.length > 0) {
                                groupPortfolios = resp.data.groupPortfolios;
                            
                                if (groupPortfolios.length > 0) {
                                    let accounts = [];

                                    groupPortfolios.forEach(portfolio => {
                                        const filteredAccounts = portfolio.accounts.filter((port => port.currencyId === this.account.currencyId));
                                        accounts = filteredAccounts.reduce((total, account) => {
                                            account['name'] = portfolio.name+" - "+account.accountName;

                                            total.push(account);

                                            return total;
                                        }, []);
                                    });
              

                                    if (accounts.length > 0) {
                                        this.accountDataList.push(...accounts);

                                        GroupService.getGroupCashAccounts(this.selectedTransfereeAccount.groupId).then(resp => {
                                            if( resp.data.status == 'success'){
                                                const cashAccount = resp.data.cashAccounts.filter((acct => acct.currencyId === this.account.currencyId))[0];

                                                this.accountDataList.push(cashAccount);
                                                
                                            } else {
                                                console.log("error getting cash accounts " + resp.data.message);
                                            }
                                        });
                                    }
                                }
                            }
                    
                        } else {
                            console.log("error getting accounts " + resp.data.message);
                        }
                    });
                }
            } else if (this.transferDestinationOption === transferDestinationOptionsIds.userAccount && this.selectedGroup && UserUtils.isUser(this.selectedTransfereeAccount.userId)) {
                this.accountDataList = this.ownAccountDataList;
            }
        },

        completeTransfer() {

            let data = {
                sourceAccountId: this.account.investmentAccountId,
                amount: this.amount,
                destAccountId: null,
                destUserId: null,
                destGroupId: null
            };
            console.log("complete transfer selected group ", this.selectedGroup)
            if (!this.selectedGroup) {
                if (this.transferDestinationOption === transferDestinationOptionsIds.groupAccount) {
                    data.destGroupId = this.selectedTransfereeAccount.groupId;
                    data.destAccountId = this.selectedTransfereeAccount.isMember ? this.selectedAccount?.investmentAccountId : null;
                } else if (this.transferDestinationOption === transferDestinationOptionsIds.userAccount) {
                    data.destUserId = this.selectedTransfereeAccount.userId;
                } else {
                    data.destAccountId = this.selectedAccount.investmentAccountId;
                }
            } else {

                data['groupId'] = this.selectedGroup.groupId;

                if (this.transferDestinationOption === transferDestinationOptionsIds.groupAccount) {
                    data.destGroupId = this.selectedTransfereeAccount.groupId;
                    data.destAccountId = this.selectedTransfereeAccount.isMember ? this.selectedAccount?.investmentAccountId : null;
                } else if (this.transferDestinationOption === transferDestinationOptionsIds.userAccount) {
                    data.destUserId = this.selectedTransfereeAccount.userId;
                } else if (this.transferDestinationOption === transferDestinationOptionsForUser.yourAccount) {
                    data.destUserId = this.$store.state.users.user.userId;
                    data.destAccountId = this.selectedAccount.investmentAccountId;
                } else {
                    data.destAccountId = this.selectedAccount.investmentAccountId;
                }
            }
            
            this.$emit('complete-transfer', data);
            this.onClose();
        },

        
    },

}
</script>

<style>
.account-transfer-modal {
    width: 600px;
    font-family: "Trebuchet MS", "Verdana";
}

.account-transfer-modal .p-dialog-header {
    padding: 16px;
}

.account-transfer-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: visible;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 760px) {
    .account-transfer-modal {
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
    }

    .account-transfer-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .account-transfer-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .account-transfer-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-input-icon-right {
    width: 100%;
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    margin-bottom: 16px;
}

.buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.p-button {
    border-radius: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.p-button:last-of-type {
    margin-left: 5px;
}

.dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.dropdown ::v-deep(.p-inputtext) {
    padding: 0;
}
.dropdown:hover, .dropdown:active {
    border-color: #33CC99;
}
.dropdown:focus, .dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}

.label-container {
    width: 55%;
    text-align: left;
}
.value-container {
    width: 45%;
    text-align: left;
}

.transfer-destination-options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0 8px;
}

.transfer-destination-options-container div {
    align-items: center;
    display: flex;
    gap: 4px;
}

.transfer-table {
    width: 100%;
}
.transfer-table .value-container .black {
    color: black;
}
.transfer-table > tr td {
    padding-bottom: 8px;
}
.transfer-table > tr:last-child td {
    padding-bottom: 0;
}

.account-type-dropdown {
    width: 100%;
}
.amount-input {
    width: 100%;
}
.amount-input {
    width: 100%;
}
.amount-input > ::v-deep(.p-inputtext) {
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    width: 100%;
    padding: 8px 0px 8px 16px;
    color: black;
}
.amount-input:hover > ::v-deep(.p-inputtext) {
    border-color: #33CC99;
}
.amount-input:focus > ::v-deep(.p-inputtext) {
    box-shadow: none;
    border-color: #33CC99;
}
@media (max-width: 760px) {

}
</style>