<template>
    <Dialog header='Buy Playdough' v-model:visible="display" :modal="true" :draggable="false" class='buy-play-dough-modal'>
        <p class='why-text' v-if="whyText != null">{{whyText}}</p>
        <p class='body-text'>Select the Wad of Playdough you wish to purchase:</p>

        <div class='buttons-container'>
            <Button  class='green-button__secondary' @click="wadSelected(1)"  :disabled="hide1000">
                <span class="p-button-label">$1,000 USD <strong>Playdough</strong> for $1.99 USD</span>
            </Button>
            <Button  class='green-button__secondary' @click="wadSelected(9)"  :disabled="hide5000">
                <span class="p-button-label">$5,000 USD <strong>Playdough</strong> for $5.99 USD</span>
            </Button>
            <Button class='green-button__secondary' @click="wadSelected(2)">
                <span class="p-button-label">$10,000 USD <strong>Playdough</strong> for $9.99 USD</span>
            </Button>
        </div>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    name: 'BuyPlayDoughModal',
    emits: ['wad-selected'],
    components: {
        Dialog
    },

    data() {
        return {
            display: false,
            cashAccount: null,
            whyText: null,
            hide1000: false,
            hide5000: false,
        }
    },


    methods: {

        open(acct, whyText, hide1000, hide5000) {
            this.display = true;
            this.cashAccount = acct;
            this.hide1000 = (hide1000 ? true : false);
            this.hide5000 = (hide5000 ? true: false );
            if( whyText) {
                this.whyText = whyText;
            }
            else {
                this.whyText = null;
            }
        },

        onClose() {
            this.display = false;
        },

        wadSelected(productId) {
            this.$emit('wad-selected', {investmentAccountId: this.cashAccount.investmentAccountId, productId: productId});
            this.onClose();
        },

        
    },

}
</script>

<style>
.buy-play-dough-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 350px;
}

.buy-play-dough-modal .p-dialog-header {
    padding: 16px;
}

.buy-play-dough-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: visible;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 760px) {
    .buy-play-dough-modal {
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
    }

    .buy-play-dough-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .buy-play-dough-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .buy-play-dough-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    margin-bottom: 16px;
}

.why-text {
    color: red;
    margin-bottom: 16px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.p-button {
    border-radius: 16px;
    margin-bottom: 8px;
}
.p-button:last-of-type {
    margin-bottom: 0;
}

@media (max-width: 760px) {

}
</style>