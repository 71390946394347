<template>
    <Dialog header='Use Website To Buy Playdough' v-model:visible="display" :modal="true" :draggable="false" closable class='buy-play-dough-via-website-modal'>

        <p class='body-text'>You have to switch to desktop or mobile web version to purchase more Playdough.</p>
        
        <div class='buttons-container'>
            <Button label='Open website in browser' class='green-button__secondary' @click="openWebsiteInBrowser"/>
        </div>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import {APP_URL} from '../../../common/config';

export default {
    name: 'BuyPlaydoughViaWebsiteModal',
    components: {
        Dialog
    },

    data() {
        return {
            display: false,
        }
    },


    methods: {

        open() {
            this.display = true;
        },

        onClose() {
            this.display = false;
        },

        openWebsiteInBrowser() {
            this.display = false;
            window.open(APP_URL, '_blank', 'noreferrer');
        },
        
    },

}
</script>

<style>
.buy-play-dough-via-website-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 400px;
}

.buy-play-dough-via-website-modal .p-dialog-header {
    padding: 16px;
}

.buy-play-dough-via-website-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: visible;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 760px) {
    .buy-play-dough-via-website-modal {
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
    }

    .buy-play-dough-via-website-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .buy-play-dough-via-website-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .buy-play-dough-via-website-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    margin-bottom: 16px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.p-button {
    border-radius: 16px;
    margin-bottom: 8px;
}
.p-button:last-of-type {
    margin-bottom: 0;
}
</style>