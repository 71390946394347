<template>
<span><small>Note: all transactions are in <b>Playdough</b>.</small></span>
  <DataTable v-if="userHasTransactions" :value="transactions"  :scrollable="true" scrollHeight="500px" :rowClass="portfolioRowClass" :resizableColumns="true" 
  columnResizeMode="expand" :paginator="true" :rows="limitPerPage" @page="onPage" :totalRecords="totalTransactions" :lazy="true" :loading="loadingTransactions" >
    <Column field="date" header="Date" headerClass="transactions-header" class="date-column">
      <template #body="slotProps">
        <div class="date">
          {{slotProps.data.transactionDate}}
        </div>
      </template>
    </Column>
    <!-- <Column field="type" header="Type" headerClass="transactions-header" class="type-column">
      <template #body="slotProps">
        <div :class="valueClass(slotProps.data, 'type')">
          {{slotProps.data.transactionType}}
        </div>
      </template>
    </Column> -->
    <Column field="amount" header="Amount" headerClass="transactions-header" class="amount-column">
      <template #body="slotProps">
        <div :class="{'amount': true, ...valueClass(slotProps.data, 'amount')}">
          {{valueFormatter(slotProps.data.amount, "currency") }}
        </div>
      </template>
    </Column>
    <Column field="fee" header="Fee" headerClass="transactions-header" class="fee-column">
      <template #body="slotProps">
        <div class="fee">
          {{slotProps.data.fee ? valueFormatter(slotProps.data.fee, "currency")  : "-"}}
        </div>
      </template>
    </Column>
    <Column field="exchangeRate" header="Exchange Rate" headerClass="transactions-header" class="exchange-rate-column">
      <template #body="slotProps">
        <div class="exchange-rate">
          {{slotProps.data.exchangeRate ? valueFormatter(slotProps.data.exchangeRate, "rate")  : "-"}}
        </div>
      </template>
    </Column>
    <Column field="quantity" header="Quantity" headerClass="transactions-header" class="quantity-column">
      <template #body="slotProps">
        <div class="quantity">
          {{slotProps.data.quantity ? valueFormatter(slotProps.data.quantity, "quantity") : "-"}}
        </div>
      </template>
    </Column>
    <Column field="description" header="Description" headerClass="transactions-header" class="description-column">
      <template #body="slotProps">
        <div class="description">
          {{slotProps.data.description}}
        </div>
      </template>
    </Column>
  </DataTable>
  <p class="no-transactions-text" v-else>We were unable to load any transaction data for this account.</p>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import {BUY_TRADE_TYPE, SELL_TRADE_TYPE} from '../../../common/constants';
import FormattingUtils from '../../../utilities/FormattingUtils';
import NumberUtils from '../../../utilities/NumberUtils';
import EventBus from '../../../event-bus';

export default {
  components: {
    DataTable,
    Column,
  },

  props: {
    transactions: {
      required: true,
    },

    totalTransactions: {
      required: true,
    },

    limitPerPage: {
      required: true,
    },

    loadingTransactions: {
      required: true,
    },
  },

  computed: {
    userHasTransactions() {
      return this.transactions.length > 0;
    },
  },
  data() {
    return {
      formatter: new FormattingUtils()
    }
  },

  methods: {
    valueFormatter(value, type) {
      if (type === 'currency') {
        return this.formatter.format(value, '$0,000.00');
      } else if (type === 'quantity') {
        return this.formatter.format(value, '0.00');
      } else if( type === 'rate') {
         return this.formatter.format(value, '0.00000');
      }
    },

    portfolioRowClass() {
      return 'portfolio-row';
    },

    valueClass(data, type) {
      if (type === 'type') {
        return [
          {
            'buy': data.transactionType === BUY_TRADE_TYPE['NAME'],
            'sell': data.transactionType === SELL_TRADE_TYPE['NAME'],
            'other': data.transactionType !== SELL_TRADE_TYPE['NAME'] && data.transactionType !== BUY_TRADE_TYPE['NAME'],
          }
        ];
      } else if (type === 'amount') {
        return {
          'negative': NumberUtils.isNegative(data.amount)
        };
      }
    },

    onPage(event) {
      EventBus.emit("transactions-page", event)
    }
  }
}
</script>

<style scoped>
::v-deep(.portfolio-row) {
  font-family: 'Trebuchet MS';
}

.no-transactions-text {
  font-family: "Trebuchet MS";
  font-size: 12px;
  color: #999;
}

.date, .type, .amount, .fee, .quantity, .description, .buy, .sell, .other {
  font-size: 12px;
}

.date, .type, .amount, .quantity, .description, .other {
  color: black;
}

.buy {
  color: #3c9;
  font-weight: bold;
}

.sell, .fee, .negative {
  color: #e63e3e;
  font-weight: bold;
}

::v-deep(.transactions-header), ::v-deep(.p-column-title) {
  font-family: 'Trebuchet MS';
  font-weight: 400;
  font-size: 12px;
  background: #fff !important;
  color: #999;
}

::v-deep(.date-column) {
  min-width: 112px;
  max-width: 112px;
}

::v-deep(.type-column) {
  min-width: 100px;
  max-width: 120px;
}

::v-deep(.amount-column) {
  min-width: 98px;
  max-width: 108px;
}

::v-deep(.fee-column) {
  min-width: 88px;
  max-width: 88px;
}

::v-deep(.exchange-rate-column) {
  min-width: 98px;
  max-width: 98px;
}

::v-deep(.quantity-column) {
  min-width: 78px;
  max-width: 78px;
}
::v-deep(.description-column){
  overflow:visible !important;
  min-width:  120px;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}

::v-deep(*:not(.pi)), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}
</style>